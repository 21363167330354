import React from "react"
import BottomNavigation from "./bottom-navigation"

const Footer = () => {
    return (
        <div id="footer">
            <footer>
                <BottomNavigation />
                <div id="copyright">
                    Winner Winner Chicken Dinner © {new Date().getFullYear()}
                </div>
            </footer>
        </div>
    )
}

export default Footer