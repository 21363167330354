import React from "react"
import { Link } from "gatsby"

const TopNavigation = () => {
    return (
        <div class="bottom-navigation">
            <nav>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/menu">Menu</Link></li>
                    <li><Link to="/order">Order</Link></li>
                </ul>
            </nav>
        </div>
    )
}

export default TopNavigation