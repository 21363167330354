import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Contact from "../components/contact"
import Hours from "../components/hours"
import FoodMenu from "../components/food-menu"

const IndexPage = () => {
    return (
        <Layout>
            <SEO />
            <div class="columns">
                <Contact />
                <Hours />
            </div>
            <FoodMenu />
        </Layout>
    )
}

export default IndexPage
