/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import "../styles/main.scss"
import Header from "./header"
import Footer from "./footer"

const Layout = (props) => {
  return (
    <div id="wrapper">
        <Header />
        <div>
            <main>{props.children}</main>
            <Footer />
        </div>
    </div>
  )
}

export default Layout
