import React from "react"

import ImgCitgoLogo from "./img-citgo-logo"

const Contact = () => {
    return (
        <section id="contact">
            <h2>Order Food at</h2>
            <address id="street">
                <a href="https://www.google.com/maps/dir//3551+N+Teutonia+Ave,+Milwaukee,+WI+53206/@43.0824812,-87.9374,17z" target="_blank" rel="noopener noreferrer">
                    3551 N Teutonia Ave. <br />Milwaukee, WI 53206
                </a>
            </address>
            <p id="special-instructions">
                Located inside the<br /> Teutonia Citgo.<br />
                <ImgCitgoLogo />
            </p>
        </section>
    )
}

export default Contact