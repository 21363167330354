import React from "react"
import { Link } from "gatsby"

import ImgWwcdLogo from "./img-wwcd-logo"
import TopNavigation from "./top-navigation"

const Header = (props) => {
    return (
        <header id="header">
            <div class="wrap">
                <div class="draw orb"><div class="draw oyb"><div class="draw mbb"><div class="draw iyb">
                    <Link to="/">
                        <div class="logo">
                            <ImgWwcdLogo />
                        </div>
                        <div class="wordmark">
                            <h1>Winner Winner Chicken Dinner</h1>
                        </div>
                    </Link>
                </div></div></div></div>
            </div>
        
            <TopNavigation />
        </header>
    )
}

export default Header