import React from "react"
import { Link } from "gatsby"

import ImgWwcdLogo from "./img-wwcd-logo"

class TopNavigation extends React.Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = { collapsed: true }
    }
    toggle() {
        this.setState({ collapsed: !this.state.collapsed })
    }
    render() {
        const classCollapseMenu = 'nav-toggle' + (this.state.collapsed ? ' collapsed' : '')
        return (
            <div className="navigation">
                <div className="draw owb">
                    <nav>
                        <button className="nav-toggle-button" onClick={this.toggle}>Navigation</button>
                        <div className="logo">
                            <Link to="/">
                                <ImgWwcdLogo />
                            </Link>
                        </div>
                        <div className="spacer">&nbsp;</div>
                        <ul className={classCollapseMenu} id="top-nav">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/menu">Menu</Link></li>
                            <li><Link to="/order">Order</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        )
    }
}

export default TopNavigation