import React from "react"

const Hours = () => {
    return (
        <section id="hours">
            <p id="hook"><em>Now Open!!</em></p>
            <h2>Hours</h2>
            <dt>Monday - Friday</dt>
            <dfn>10:00 am to 7:00 pm</dfn>
            <dt>Saturday</dt>
            <dfn>12:00 noon to 5:00  pm</dfn>
            <dt>Sundays</dt>
            <dfn>Closed</dfn>
        </section>
    )
}

export default Hours