import React from "react"

const FoodMenu = () => {
    return (
        <section id="food-menu">
            <h2>Food Menu</h2>
            <div class="draw oyb"><div class="draw mbb"><div class="draw iyb">
                <div>
                    <h3>Chicken Wings / Wedges</h3>
                    <ul>
                    <li><strong><span class="quantity">3</span>Chicken Wings</strong><span class="price">4.25</span></li>
                    <li><strong><span class="quantity">5</span> Chicken Wings</strong><span class="price">5.75</span></li>
                    <li><strong><span class="quantity">7</span>Chicken Wings</strong><span class="price">6.75</span></li>
                    </ul>
                </div>
                <div>
                    <h3>Chicken Strips / Wedges</h3>
                    <ul>
                    <li><strong><span class="quantity">3</span>Chicken Strips</strong><span class="price">5.00</span></li>
                    <li><strong><span class="quantity">5</span>Chicken Strips</strong><span class="price">7.00</span></li>
                    </ul>
                </div>
                <div>
                    <h3>Chicken Bites / Wedges</h3>
                    <ul>
                    <li><strong><span class="quantity">10</span>Chicken Bites</strong><span class="price">5.00</span></li>
                    </ul>
                </div>
                <div>
                    <h3>Other Items</h3>
                    <ul>
                    <li><strong>Empanadas</strong><span class="price">3.00</span></li>
                    <li><strong>Puerto Rican Rice</strong><span class="price">3.00</span></li>
                    <li><strong>Mozzarella Sticks</strong><span class="price">3.75 / 5.00</span></li>
                    <li><strong>Tacos</strong><span class="price"></span><span class="price">3.00 / 5.00</span></li>
                    <li><strong>Nachos</strong><span class="price"></span><span class="price">5.00</span></li>
                    </ul>
                </div>
            </div></div></div>
        </section>
    )
}

export default FoodMenu